<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
				<v-card class="shadowCard">
					<v-card-title class="text-subtitle-1">
					  Encuesta de ambiente laboral
					  <v-spacer></v-spacer>
	      		<v-btn
							color="primary"
							dark
							@click="initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>
					<v-card-text >

		      	<v-row>
		      		<v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
		      		<v-spacer></v-spacer>
			      	<v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
			          <v-text-field
			            v-model="search"
			            dense
			            filled
			            single-line
			            label="Buscar"
			            append-icon="mdi-magnify"
			          ></v-text-field>  
			        </v-col>
		      	</v-row>

		        <v-tabs
		          v-model="tab"
		          dense
		          align-with-title
		        >
		          <v-tabs-slider></v-tabs-slider>

		          <v-tab>Depto y Cat.</v-tab>
		          <v-tab>Jefe y Cat.</v-tab>
		          <v-tab>Categoría</v-tab>
		          <v-tab>Esc y Cat.</v-tab>
		          <v-tab>Cometarios</v-tab>
		          <v-tab>Faltantes</v-tab>
		        </v-tabs>

		        <v-tabs-items v-model="tab">

		        	<!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headers1"
						          :items="departamentos"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-row>
				          		<v-col cols="12">
				          			<div>
				          				<b>Total de jefes: <v-chip small @click="dialogJefes = true">{{ jefes.length }}</v-chip></b><br/>
				          			</div>
				          		</v-col>
				          	</v-row>

						        <v-data-table
						          :headers="headersJefe"
						          :items="jefe"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersCategoria"
						          :items="categorias"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersEscuela"
						          :items="escuela"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersComentario"
						          :items="comentarios"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-row>
				          		<v-col cols="12">
				          			<div>
				          				<b>Total: {{ empleados }}</b><br/>
				          				<b>Faltan: {{ faltantes.length }}</b><br/>
				          				<b>Avance: {{ ((( empleados - faltantes.length ) / faltantes.length ) * 100 ).toFixed(2 ) }} % </b>
				          			</div>
				          		</v-col>
				          	</v-row>
						        <v-data-table
						          :headers="headersFaltantes"
						          :items="faltantes"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				    </v-tabs-items>

		      </v-card-text>
				</v-card> 
			</v-col>
		</v-row>


		<v-dialog
		  v-model="dialogJefes"
		  max-width="500px"
		  transition="dialog-transition"
		>
		  <v-card flat>
        <v-card-text>
	        <v-data-table
	          :headers="headerJefes"
	          :items="jefes"
	          class="elevation-0"
	          dense
	        >
	        </v-data-table>
        </v-card-text>
      </v-card>
		</v-dialog>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>

<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import validarErrores       from '@/mixins/validarErrores'
	import XLSX                 from 'xlsx'
	import funcionesExcel       from '@/mixins/funcionesExcel'

	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers1: [
        { text: 'Departamento'    , value: 'departamento'        },
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            }, 
      ],

      headersCategoria: [
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

      headersComentario:[
      	{ text: 'Categoría'         , value: 'categoria'          },  
        { text: 'Comentario'        , value: 'comentario'         },  
      ],

      headersFaltantes:[
      	{ text: 'Nombre'         , value: 'nombre_completo'   },  
      	{ text: 'Puesto'         , value: 'puesto'            },  
      	{ text: 'Departamento'   , value: 'departamento'      },  
      	{ text: 'Plantel'        , value: 'plantel'           },  
      	{ text: 'Jefe'           , value: 'jefe'              },  
      ],


			headersJefe: [
        { text: 'Jefe'            , value: 'nombre_completo'     },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

			headerJefes: [
        { text: 'Jefe'            , value: 'nombre_completo'     },  
        { text: 'Puesto'          , value: 'puesto'              },  
      ],

      headersEscuela: [
        { text: 'Escuela'         , value: 'unidad_negocio'      },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

			search:'',
      data:[],

      tab: null,
			ciclos:[],
      ciclo:null,
      faltantes:[],
			categorias:[],
			comentarios:[],
			jefe:[],
			escuela:[],
			departamentos:[],
			empleados: 0,
			jefes: [],
			dialogJefes: false,
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

		},

		watch: {
			ciclo( value ){

				if( value ){ this.initialize() }

			}
		},

		async created () {
    	await this.getCiclos()
		},

		methods:{

   		getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data.filter( el => !el.ciclo.match('FE') && !el.ciclo.match('INVER') && !el.ciclo.match('CAMBIOS') && !el.ciclo.match('EXCI') )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

			initialize ( ) {
				this.cargar = true
				const payload = {
					id_ciclo   : this.ciclo,
				}

				this.$http.post('encuestas.ambiente.resultados', payload).then(response=>{
					this.faltantes     = response.data.faltantes
					this.categorias    = response.data.categorias
					this.comentarios   = response.data.comentarios
					this.jefe          = response.data.jefe
					this.escuela       = response.data.escuela
					this.departamentos = response.data.departamentos
					this.empleados     = response.data.empleados.length
					this.jefes         = response.data.jefes
					this.cargar       = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},
    }
	}
</script>